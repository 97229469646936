.rating-page {
  font-family: 'Arimo';
  font-style: normal;
  padding-top: 113px;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
}

.rating-title {
  text-align: center;
  font-size: 64px;
  line-height: 70px;
  margin-bottom: 63px;
}

.rating-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rating-barrier {
  width: 850px;
  height: 2px;
  margin: 0 0 20px 0;
  background: rgb(196, 196, 196);
  border: none;
}
.rating-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 800px;
  height: 70px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 150px 1fr 150px;
}

.rating-position {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 26px;
}

.rating-name {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
}

.rating-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
  line-height: 18px;
}

.rating-percent {
  margin-bottom: 8px;
}

.rating-item_first,
.rating-item_p {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: content-box;
}

.rating-item_first {
  height: 100px;
  background: rgba(255, 148, 0, 0.1);
  grid-template-columns: 150px 1fr 156px;
  margin-bottom: 40px;

  &>.rating-position {
    font-size: 36px;
    line-height: 26px;
    color: #FFFFFF;
    position: relative;
    z-index: 0;

    &:after {
      position: absolute;
      display: block;
      width: 90px;
      height: 90px;
      content: "";
      background: #FF9400;
      border: 2px solid #BF8330;
      border-radius: 100%;
      z-index: -1;
    }
  }

  &>.rating-name {
    font-size: 24px;
    line-height: 26px;
  }

  &>.rating-stats {
    font-size: 24px;
    line-height: 26px;
  }
}

.rating-item_p {
  background: rgba(0, 168, 118, 0.1);

}

@media (max-width: 870px) {
  .rating-page {
    padding-top: 70px;
  }

  .rating-title {
    font-size: 36px;
    line-height: 70px;
  }

  .rating-item {
    width: 95%;
  }
}

@media (max-width: 600px) {
  .rating-page {
    padding-top: 60px;
  }

  .rating-title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .rating-item {
    height: 60px;
    grid-template-columns: 60px 1fr 110px;

    &,
    .rating-item_first,
    .rating-item_p {
      &>.rating-title {
        font-size: 28px;
        line-height: 40px;
      }

      &>.rating-position {
        font-size: 16px;
        line-height: 22px;
      }

      &>.rating-name {
        font-size: 16px;
        line-height: 22px;
      }

      &>.rating-stats {
        font-size: 16px;
        line-height: 18px;
      }

      &>.rating-percent {
        margin-bottom: 4px;
      }
    }

    &_first {
      margin-bottom: 20px;

      &>.rating-position {
        font-size: 24px;
        line-height: 26px;

        &:after {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
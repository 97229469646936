$green: #00A876;
$shadow: rgba(0, 168, 118, 0.2);

.container {
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arimo';
}

.App-footer {
    background-color: #FFFFFF;
    box-shadow: 0px -30px 40px -30px $shadow;
    position: relative;
    z-index: 1;
    min-height: 93px;
    display: flex;
    align-items: center;
}


.footer {
    width: 100%;
    padding: 0 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    &_text {
        display: block;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
    }
}



.infoAccordion {
    position: absolute;
    bottom: 100px;
    max-width: 1200px;
    margin-right: 5px;
    box-shadow: 2px 4px 8px 4px rgb(0 168 118 / 20%);
}



.socialicons_links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding-top: 10px;
}

.socialicons_link_item {
    display: inline-block;
}

.socialicons_link_svg {

    transition: 1s;
}

.socialicons_link_svg:hover {
    transform: rotate(360deg);
}

.button_info {
    border: none;
    background-color: #FFFFFF;
}

@media (max-width: 768px) {
    .footer {
        justify-content: center;
    }
}
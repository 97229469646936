.warning-dispay {
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-family: 'Arimo';


    &_title {
        min-height: 68px;
        background: #FF5900;
        border-radius: 8px 8px 0 0;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 20px;

    }

    &_text {
        min-height: 112px;
        border-radius: 0 0 8px 8px;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 5px;
    }
}

@media (max-width: 375px) {
    .warning-dispay {
        display: block;
    }
}
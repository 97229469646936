.statistics_draw {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_header {
        font-family: 'Arimo';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 55px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
    }

    &_part {
        max-width: 1000px;
        position: relative;
        margin-top: 56px;
    }

    &_selection {
        display: flex;
        width: 520px;
        justify-content: space-between;
    }

    &_btn {
        width: 160px;
        height: 42px;
        border: 2px solid #006D4C;
        border-bottom: none;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;
        transition: background-color .3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: rgba(0, 168, 118, 0.1);
        }

        &_txt {
            font-family: 'Arimo';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            align-items: center;
            text-align: center;

            color: #000000;
        }
    }

    &_graph {
        width: 1000px;
        height: 328px;
        border: 2px solid #006D4C;
        margin-bottom: 150px;
        padding: 25px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
.selector_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: 0;
}
.selector_box > divc{
    margin-top: 0;
}
.selector_box > div > div {
    border: 2px solid #006D4C
}

#graph {
    &_max {}

    &_middle {
        display: none;
    }

    &_mini {
        display: none;
    }
}

.opened {
    background: rgba(0, 168, 118, 0.1);
}

@media (max-width: 1024px) {
    .statistics_draw_part {
        width: 100%;
    }
    .statistics_draw_graph {
        width: 100%;
    }
}
// 768
// Отображение от 768 до 999 некорректно, поэтому оставляю больший показатель
@media (max-width: 999px) {
    .statistics_draw {
        &_header {
            font-size: 36px;
            line-height: 41px;
        }

        &_graph {
            // max-width: 740px;
            height: 328px;
            border: 2px solid #006D4C;
            margin-bottom: 55px;
        }
    }

    #graph {
        &_max {
            display: none;
        }

        &_middle {
            display: block;
        }

        &_mini {
            display: none;
        }
    }
}

// 375
// Отображение от 375 до 738 некорректно, поэтому оставляю больший показатель
@media (max-width: 738px) {
    .statistics_draw {
        &_header {
            font-size: 28px;
            line-height: 32px;
        }

        &_part {
            margin-top: 20px;
        }

        &_graph {
            margin: 0 auto;
            // max-width: 316px;
            height: 273px;
            border: 2px solid #006D4C;
            margin-bottom: 20px;
        }

        &_selection {
            width: 315px;   
        }

        &_btn {
            width: 33%;
            height: 42px;

            &_txt {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    #graph {
        &_max {
            display: none;
        }

        &_middle {
            display: none;
        }

        &_mini {
            display: block;
        }
    }
}

@media (max-width: 738px) {
    .statistics_draw {
        &_header {
            font-size: 28px;
            line-height: 32px;
        }

        &_part {
            margin-top: 20px;
        }

        &_graph {
            margin: 0 auto;
            height: 273px;
            border: 2px solid #006D4C;
            margin-bottom: 20px;
        }

        &_selection {
            width: 100%;   
        }

        &_btn {
            width: 32%;
            height: 42px;

            &_txt {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
$green: #00A876;
$shadow: rgba(0, 168, 118, 0.2);

.back_button {
    background: rgba(0, 168, 118, 0.6) !important;
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    min-width: auto !important;
    position: absolute !important;
    top: 157px !important;

    &:hover {
        background: #00A876 !important;
    }
}

.task-page {
    width: 100%;
    padding-top: 45px;
    padding-bottom: 10px;
    color: black;
    font-family: 'Arimo';
}

.task-card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
}

.task-title {
    font-weight: 400;
    font-size: 64px;
    line-height: 74px;
    padding-bottom: 30px;
    padding-left: 70px;
}

.task-type_title {
    padding-bottom: 10px;
}

.task-info {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 20px;

}

.task-info span {
    font-weight: 400;
    padding-left: 20px;

}

.task-card_rightpart {
    display: flex;
    gap: 44px;
    padding-top: 15px;
}

.task-type {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

.task-type_title_item {
    font-weight: 400;
    line-height: 24px;
}

.fa-circle {
    color: rgba(0, 168, 118, 0.1);
    padding-right: 8px;
}

.fa-circle:nth-of-type(-n+2) {
    color: $green;
}

.result-btn {
    display: inline-block;
    cursor: pointer;
    background: #00A876;
    border: 2px solid #006D4C;
    color: #fff;
    border-radius: 8px;
    padding: 8px 25px;
    transition: all 250ms;

    &:hover {
        background: #fff;
        color: #006D4C;
    }
}

@media (max-width: 768px) {
    .back_button {
        top: 140px !important;
    }

    .task-title {
        font-size: 32px;
        line-height: 37px;
    }

    .task-info {
        font-size: 16px;
        line-height: 18px;
    }

    .task-type_title {
        font-size: 16px;
        line-height: 18px;

        &_item {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media (max-width: 570px) {
    .task-info {
        width: 50%;
    }

    .task-card {
        display: block;
    }
}

@media (max-width: 375px) {
    .task-card {
        display: none;
    }

    .back_button {
        display: none !important;
    }
}
$green: #00A876;
$shadow: rgba(0, 168, 118, 0.2);


@font-face {
    font-family: 'Arimo';
    src: url('../../fonts/Arimo-Regular.ttf');
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.demo-task {
    color: $green;
    padding: 10px 0 41px 0;
    font-family: 'Arimo';
    font-size: 35px;
}

.running-string {
    display: flex;
    background: rgb(255, 255, 255);
    min-width: 100%;
    height: 5rem;
    border: 8px solid rgba(0, 168, 118, 0.6);
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 3.063rem;
    overflow: hidden;

}

.input-text {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: right;
}

.end-string {
    background: rgba(0, 168, 118, 0.6);
    color: #000000;
    opacity: .68;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    white-space: pre;
}

.start-string {
    white-space: pre;
    color: #000000;

}

.shake {

    animation: shake 0.5s;
    animation-iteration-count: 1;
    animation-duration: .8s;
}


@keyframes shake {

    0%,
    50%,
    100% {
        transform: rotate(0deg);
    }

    10%,
    30% {
        transform: rotate(-1deg);
    }

    20%,
    40% {
        transform: rotate(1deg);
    }
}

@media (max-width: 768px) {
    .running-string {
        min-width: 100%;
        height: 100%;
    }

    .input-text {
        font-size: 24px;
    }
}

@media (max-width: 375px) {
    .running-string {
        display: none;
    }
}
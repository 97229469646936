@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

.page {
  width: 100%;
  flex-grow: 1;
  position: relative;
}

.box {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.text-404 {
  font-family: Montserrat;
  font-size: 120px;
  font-weight: 700;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: transparent;
}

.text {
  font-family: Montserrat;
  font-weight: 300;
}
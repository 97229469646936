$shadow: rgba(0, 168, 118, 0.2);
.container {
    width: 95vw;
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.input {
    outline: none;
}

.window {
    background: white;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    position: relative;
    margin: 0 auto;

    -webkit-box-shadow: 0 0 18px 11px $shadow;
    -moz-box-shadow: 0 0 18px 11px $shadow;
    box-shadow: 0 0 18px 11px $shadow;
}
.xMark {
    color: #000;
}
.form-wrapper {
    padding-top: 43px;
    padding-bottom: 51px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.form {
    max-width: 400px;
}
.input {
    &:not(:first-of-type) {
        margin-top: 23px;
    }
    label {
        color: #207E62;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
    input {
        font-size: 26px;
        font-weight: 400;
        line-height: 30px;
        padding: 0;
        padding-bottom: 1px;
        margin-top: 8px;
        height: 36px;
        text-align: left;
        &::after {
            display: block;
            width: 100%;
            border-bottom: 1px solid #207E62;
        }
    }
}

.reg-text {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    color: #00A876;
}

.redirect_button {
    background: none;
    border: none;
    color: #00A876;
    text-decoration: none;
    cursor: pointer;
    margin-left: 5px;
    font-size: 1em;

    &:hover {
        color: #14523F;
    }

    &:active {
        color: #006D4C;
    }
}

.reg-black {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
}

.btn {
    height: 50px;
    width: 300px;
    background: #00A876 !important;
    border: 2px solid #006D4C !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #FFFFFF;
    
    &:hover {
        background: #FFFFFF !important;
        color: #00A876;
    }
}

@media (max-width: 768px) {
    .window {
        max-width: 600px !important;
    }
    .reg-text {
        font-size: 36px;
        line-height: 41px;
    }
    .form {
        max-width: 340px;
    }

    .input {
        &:not(:first-of-type) {
            margin-top: 16px;
        }
        input {
            font-size: 22px;
            line-height: 25px;
            height: auto;
            margin-top: 0;
            padding-top: 2px;
            padding-bottom: 4px;
        }
        label {
            font-size: 16px;
            line-height: 18px;
        }
    }
    .reg-text {
        font-size: 36px;
        line-height: 41px;
        margin-bottom: 28px;
    }

    .btn {
        font-size: 16px !important;
        line-height: 18px !important;
        width: 225px;
    }
}

@media (max-width: 560px) {
    .form-wrapper {
        padding-left: 3%;
        padding-right: 3%;
    }

    .form {
        width: 100%;
        padding-right: 2%;
        &>label {
            width: 85vw;
        }
    }

    .input {
        width: 100%;
        font-size: 22px;
        line-height: 25px;
    }

    .reg-text {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .reg-black {
        font-size: 16px;
        line-height: 18px;
    }
}
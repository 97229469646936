$green: #00A876;
$shadow: rgba(0, 168, 118, 0.2);


.tasks-page {
    color: black;
    font-family: 'Arimo';
    width: 100%;
    height: 100%;
}

.tasks-container {
    max-width: 1146px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 103px;
}

.filters-form {
    width: 230px;
}

.filters-checkbox {
    font-size: 16px;
    line-height: 18px;
    user-select: none;

    &_custom {
        position: absolute;
        opacity: 0;
        width: auto;
        height: auto;
    }

    &_custom+label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    &_custom+label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #000000;
        border-radius: 2px;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    &_custom:checked+label::before {
        border-color: $green;
        background-color: $green;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
}

.filters-title {
    padding-bottom: 14px;
}

.filters-checkbox:not(:last-of-type) {
    padding-bottom: 8px;
}

.form-checkboxs {
    padding-bottom: 14px;
}

.form-control {
    background: none;
    border: 2px solid #000000;
    border-radius: 8px;
    padding: 9px 9px;
    width: 100%;
}

.cards-box {
    width: 740px;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}


.Pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.cards-box-empty {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

@media (min-width: 1240px) {
    .tasks-container {
        padding-right: 2%;
    }
}

@media (max-width: 1040px) {
    .tasks-container {
        flex-direction: column;
    }

    .form-selector {
        margin-top: 0;
    }

    .form-checkboxs {
        margin-right: 10%;
    }

    .filters-form {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 41px;
    }

    .cards-box {
        margin: 0 auto;
    }
}

@media (max-width: 840px) {
    .filters-form {
        justify-content: space-between;
    }

    .tasks-container {
        padding-top: 72px;
    }

    .cards-box {
        width: 100%;
    }

    .filters-form {
        justify-content: flex-start;
    }

    .form-checkboxs {
        width: 50%;
        margin: 0;
    }

    .form-selector {
        width: 50%;
    }

    .__select {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .filters-form {
        margin-bottom: 62px;
    }

    .tasks-container {
        padding-top: 44px;
    }

    .cards-box {
        flex-direction: column;
        align-content: center;
    }

    .filters-title {
        font-size: 16px;
    }

    .filters-checkbox {
        font-size: 14px;
    }

    .filters-checkbox_custom+label::before {
        width: 14px;
        height: 14px;
    }
}
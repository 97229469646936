.Xmark {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
}

.X {  
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}


$green: #00A876;
$white: #FFFFFF;
$deep_green: #006D4C;
$dark_green: #14523F ;
$shadow: rgba(0, 168, 118, 0.2);

.container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arimo';
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Main-part {
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: auto;
}

.btn {
  width: 100%;
  max-width: 200px;
  height: 43px;
  box-sizing: border-box;

  background: $green;
  border: 2px solid $deep_green;
  border-radius: 8px;

  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: $white;
  cursor: pointer;

  &:hover {
    background: $deep_green;
    border: 2px solid $dark_green;
  }

  &:active {
    background: $white;
    border: 2px solid $deep_green;
    color: $deep_green;
  }
}

.btn-default {
  background: $white;
  border: 2px solid $deep_green;
  color: $deep_green;

  &:hover {
    color: $white;
    background: $deep_green;
    border: 2px solid $dark_green;
  }

  &:active {
    background: $white;
    border: 2px solid $deep_green;
    color: $deep_green;
  }
}
@media (max-width: 1240px) {
  .container {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media (max-width: 800px) {
  .container {
    padding-left: 2%;
    padding-right: 2%;
  }
}